<template>
  <auth-layout>
    <div v-if="!showConfirmation">
      <div class="cell auto text-center">
        <h1>{{ $t('forms.welcome') }}</h1>
        <p>{{ $t('forms.choose_password') }}</p>
      </div>
      <div class="spacer--20"></div>
      <form @submit.prevent="formSubmit">
        <card-item :margin="true">
          <ab-input-field
            v-if="!hideCode"
            :label="$t('forms.password_reset_code')"
            v-model="resetData.reset_password_code"
            type="text"
            :error="$v.resetData.reset_password_code.$error"
            :errorMessage="$t('forms.place_password_reset_code')"
          ></ab-input-field>
          <ab-input-field
            v-if="!hideEmail"
            label="Email"
            v-model="$v.resetData.email.$model"
            type="email"
            :error="$v.resetData.email.$error"
            :errorMessage="$t('forms.email')"
          ></ab-input-field>
          <ab-input-field
            label="Wachtwoord"
            v-model="resetData.password"
            type="password"
            :error="$v.resetData.password.$error"
            :errorMessage="$t('forms.password_length')"
          ></ab-input-field>
          <ab-input-field
            :label="$t('forms.confirm_password')"
            v-model="resetData.password_confirmation"
            type="password"
            :error="$v.resetData.password_confirmation.$error"
            :errorMessage="$t('forms.password_does_not_match')"
          ></ab-input-field>
        </card-item>
        <div class="spacer--20"></div>
        <div class="grid-x align-middle">
          <div class="cell auto text-center">
            <p>
              <a @click.prevent="generatePassword" href="">{{
                  $t('forms.generate_password')
                }}</a>
            </p>
            <div class="spacer--20"></div>
            <button type="submit" @click="formSubmit" class="button--primary">
              {{ $t('forms.save_password') }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="showConfirmation">
      <div class="cell auto text-center">
        <h1>{{ $t('forms.welcome') }}</h1>
        <p>
          {{ $t('forms.password_created') }}<br/>
          {{ $t('forms.happy_day_team_abrnd') }}
        </p>
        <button type="submit" @click="goToLogin" class="button--primary">
          {{ $t('forms.login') }}
        </button>
        &nbsp;<a @click.prevent="copyPassword" href="">{{
          $t('forms.copy_password')
        }}</a
      ><br/>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import backend from '@/backend'
import generator from 'generate-password'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordVerification',
  data () {
    return {
      hideCode: false,
      hideEmail: false,
      resetData: {
        email: '',
        reset_password_code: '',
        password: '',
        password_confirmation: ''
      },
      submitStatus: null,
      showConfirmation: false
    }
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('code')) {
      this.resetData.reset_password_code = urlParams.get('code')
      this.hideCode = true
    }
    if (urlParams.has('email')) {
      this.resetData.email = urlParams.get('email')
      this.hideEmail = true
    }
  },
  validations: {
    resetData: {
      email: {
        required,
        email
      },
      reset_password_code: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        minLength: minLength(12)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    generatePassword () {
      const password = generator.generate({
        length: 15,
        numbers: true,
        lowercase: true,
        uppercase: true
      })
      this.resetData.password = password
      this.resetData.password_confirmation = password
    },
    formSubmit (e) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        backend
          .post(
            `${process.env.VUE_APP_URL}/users/reset_password`,
            this.resetData
          )
          .then(() => {
            this.showConfirmation = true
          })
          .catch(response => {
            this.submitStatus = this.$t('forms.data_is_incorrect')
          })
      }
    },
    goToLogin () {
      this.$router.push('/login')
    },
    copyPassword () {
      // "Hack" that creates a temporary textarea with our password,
      // executes a copy, and then destroys the textarea again.
      const el = document.createElement('textarea')
      el.value = this.resetData.password
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$notify({
        group: 'custom-template',
        title: false,
        position: 'bottom left',
        text: this.$t('forms.password_copied')
      })
    }
  },
  components: {
    'auth-layout': AuthLayout
  }
}
</script>
